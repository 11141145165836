import React from "react";
import games from "./games"
import GameListing from "./GameListing";

export default class GamesHomePage extends React.Component {
    render() {
        return <div className="games-landing">
            <h1>Game Designs by Oliver Barnum</h1>
            <div className="game-table-of-contents">
                <ul>    
                    {games.map(game => 
                        <li className="game-name-header-link" key={game.name}><a href={`#${game.name}`}>{game.name}</a> <span className="game-link-subtitle">{game.subtitle}</span></li>
                    )}
                </ul>
            </div>
            <div className="game-list">
                {games.map(game => 
                    <React.Fragment>
                    <GameListing key={game.name} game={game}/>
                    <p className="back-to-top"><a href="#top">Back to Top</a></p>
                    </React.Fragment>
                )}
            </div>
        </div>
        
    }
}