import React from "react";
import "./ShopOptions.css"
import { Image, Button, Card } from "react-bootstrap";

type PhysicalProductProps = {
	cart: any,
    cartItems: any,
    product: any,
    isLoading: boolean,
    onClickCallback: any
}

export default class PhysicalProduct extends React.Component<PhysicalProductProps> {
    getMessage() {
        var defaultMessage = "Add to Cart"
        if (this.props.cart === undefined) {
            return defaultMessage
        }
        if (this.props.cart === null) {
            return defaultMessage
        }
        if (this.props.cartItems === undefined) {
            return defaultMessage
        }
        if (this.props.cartItems === null) {
            return defaultMessage
        }
        for(var i = 0; i < this.props.cartItems.length ; i++) {
            var cartItem = this.props.cartItems[i]
            if (cartItem["sku_id"] !== this.props.product.link) {
                continue;
            }
            return defaultMessage + " (" + cartItem["quantity"] + ")"
        }
        return defaultMessage
    }
    
    render() {
        var message = this.getMessage()
        return (
            <Card className="productCard">
                <Card.Img className="shopImage" as={Image} variant="top" src={this.props.product["image_link"]} alt={this.props.product.name + " Image"}/>
                <Card.Body>
                    <Card.Title className="productTitle">{this.props.product.name}</Card.Title>
                    <Card.Text className="productDescription">{this.props.product.description}</Card.Text>
                    <Button 
                        disabled={this.props.isLoading} 
                        variant="outline-dark" 
                        onClick={() => {this.props.onClickCallback(this.props.product)}} 
                        className="productActionButton">{message}
                    </Button>
                </Card.Body>
            </Card>
        );
    }
}