import React from "react";
import "./Explanation.css"
import portrait from "./portrait_00.png"

export default class Explanation extends React.Component {

    render() {
        return (
            <div className="col explanation-column">
                <h1>Go Next Games</h1>
                <p>Hi! I'm Oliver. Go Next Games is a one man development studio based out of Austin, Texas.</p>
                <img className="portrait" src={portrait} alt="Oliver"/>
                <p>In online sports, it is common to say "gg go next" when a match is ending to show good sportsmanship and readiness to play the next game. To embody this good sportsmanship and perserverence, I named the company Go Next Games.</p>
                <h3 className="projects-header">Current Projects</h3>
                <div className="project-links">
                    <a className="project" href="https://www.templative.net">
                        <h3>Templative</h3>
                        <p>Speedrun turning your board game ideas into board game sales while skipping the tedium.</p>
                    </a>
                    <a className="project" href="https://www.gonextgames.com/games/caps-and-hammers">
                        <h3>Caps and Hammers</h3>
                        <p>Experience the Cold War in just 30 minutes!</p>
                    </a>
                </div>
                <p>Curious about my unpublished games? <a className="go-to-games" href="https://www.gonextgames.com/games">Go to the Games page</a>.</p>
            </div>
        );
    }
}