import React from "react";
import YouTube from "react-youtube";

const links = [
    {
        "action": "Share",
        "icon": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share" viewBox="0 0 16 16">
            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
        </svg>,
        "urlKey": "shareUrl"
    },
    {
        "action": "Rules",
        "icon": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
            <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
        </svg>,
        "urlKey": "rulesPdfLink"
    },
    {
        "action": "Print & Play",
        "icon": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1"/>
        </svg>,
        "urlKey": "printAndPlayLink"
    },
    {
        "action": "Tabletop Simulator",
        "icon": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
            <path d="M 14.941359,4.6773602 A 0.78338693,0.78338693 0 0 1 13.847438,5.3980768 L 11.89555,10.106545 H 4.0955244 L 2.1452042,5.4002704 A 0.78338693,0.78338693 0 1 1 2.4500979,5.1718346 L 5.1762848,7.4029207 5.8318231,3.4421166 A 0.78338693,0.78338693 0 1 1 6.2924542,3.3841454 L 7.9942847,7.0503959 9.6995615,3.3747448 a 0.78338693,0.78338693 0 1 1 0.4568705,0.065184 L 10.812283,7.4013592 13.546616,5.16714 a 0.78338693,0.78338693 0 1 1 1.394743,-0.4897741 z m -2.2455,7.5596848 H 3.2952154 v 1.880132 h 9.4006436 z m -0.86956,-1.629446 H 4.1735497 v 1.128081 h 7.6527493 z"/>
        </svg>,
        "urlKey": "tabletopSimulatorLink"
    },
    {
        "action": "Tabletop Playground",
        "icon": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
            <path
            d="M 1.247037,14.455881 H 11.626102 V 12.380069 H 1.247037 Z M 9.3354418,2.4403912 9.8177222,2.6960542 C 10.920672,1.186938 11.939895,1.5941471 11.939895,1.5941471 l -0.747985,1.833641 1.233034,0.6573325 0.09929,1.1416996 2.228729,3.7624171 -1.506694,1.0161097 c 0,0 -0.927888,-1.2589922 -1.371766,-1.5105006 C 11.505699,8.2872586 10.448419,8.1402234 9.9311957,8.0796841 L 7.5945221,5.4188312 7.1793599,5.7841772 8.4646337,7.2479747 c -0.00448,0.00659 -0.0087,0.013189 -0.013189,0.019652 C 7.2135681,9.280129 9.2471721,10.924172 10.820984,11.826801 H 2.0541351 C 2.360663,0.89695617 9.3353943,2.4403252 9.3353943,2.4403252 Z"
     />
        </svg>,
        "urlKey": "tabletopPlaygroundLink"
    },
]
export default class GameListing extends React.Component {
    _onReady(event) {
        event.target.pauseVideo();
    }
    render() {
        return <div className="row game">
            <div className="col-12 col-lg-6">
                <h2 id={this.props.game.name}>{this.props.game.name}</h2>
                <p>{this.props.game.subtitle}</p>
                <p>{this.props.game.pitch}</p>
                <div className="inline-share-links">
                    { links.map(link => 
                        <a key={link.action} className="btn btn-secondary game-share-button" href={this.props.game[link.urlKey]}>
                            {link.icon}
                            {link.action}
                        </a>
                    )}
                </div>
                { this.props.game.youtubePitchId !== null && 
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                        <YouTube
                        videoId={this.props.game.youtubePitchId}
                        opts={{
                            width: '100%',
                            height: '100%',
                            playerVars: {
                            autoplay: 0,
                            },
                        }}
                        onReady={this._onReady}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        />
                    </div>
                    
                }
                
            </div>
            <div className="col-12 col-lg-6">
                <img src={this.props.game.sellSheetUrl} className="sellsheet-img"/>
            </div>
        </div>        
    }
}