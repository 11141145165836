
import React from "react";
import "./CompanyInfo.css"
export default class ArtLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <h1 className="content-header">Submit an Art Portfolio</h1>
                    <p>We're are on the lookout for great artists to work with. If you're interested in working with us, submit a portfolio below.</p>
                </div>
            </div>
        );
    }
}