import React from "react";
import "../TopBar.css"

import Nav from 'react-bootstrap/Nav';
import { ColorScheme } from "../ColorScheme";
import { Endpoint, EndpointType } from "./TopBar";

type NavLinkProps = {
    endpoint:Endpoint
    colorScheme:ColorScheme
}

export default class NavbarLink extends React.Component<NavLinkProps> {    
    goToLink = () => {
        if (this.props.endpoint.type === EndpointType.Local) {
            window.location.href = `${window.location.origin}/${this.props.endpoint.endpoint}`
        }
        else if (this.props.endpoint.type === EndpointType.Global) {
            window.location.replace(this.props.endpoint.endpoint);
        }
    }
    render() {
        return (
            <Nav.Link key={this.props.endpoint.name} className={this.props.colorScheme} onClick={this.goToLink}>{this.props.endpoint.name}</Nav.Link>
        );
    }
}