import React from "react";
import GameListing from "./GameListing";

export default class GamePage extends React.Component {
    render() {
        return <div className="games-landing">
            <GameListing game={this.props.game}/>
            <a href="https://www.gonextgames.com/games">Back to Games</a>
        </div>
        
    }
}