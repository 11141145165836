import React from "react";
import CartItem from "./CartItem"

export default class CartItemsFragment extends React.Component {
    render() {
        var cartItems = this.props.cartItems.map((cartItem) => 
            <CartItem 
                isModifyingCartQuantity={this.props.isModifyingCartQuantity}    
                modifyItemInCartCallback={this.props.modifyItemInCartCallback} 
                key={cartItem["id"]} 
                item={cartItem}
            />
        )
        return (
            <React.Fragment>
                {cartItems}
            </React.Fragment>
        );
    }
}