import React from "react";
import { Routes, Route } from "react-router-dom";
import "./GamesLanding.css"
import GamesHomePage from "./GamesHomePage";
import GamePage from "./GamePage";
import games from "./games"
const convertToUrlSafeString = (name) => {
    return `/${name
        .toLowerCase()          // Convert to lowercase
        .replace(/[^a-z0-9\s]/g, '')  // Remove any non-alphanumeric characters (except spaces)
        .trim()                 // Trim any leading or trailing spaces
        .replace(/\s+/g, '-')   // Replace spaces with hyphens
    }`;
}
export default class GamesLanding extends React.Component {
    render() {
        return <div className="games-landing">
            <Routes>
                <Route path={"/"} element={<GamesHomePage/>} />
                {games.map(game => 
                    <Route path={convertToUrlSafeString(game.name)} element={<GamePage game={game}/>} />
                )}
            </Routes>
        </div>
        
    }
}