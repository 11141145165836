import React from "react";
import "./Cart.css"
import { Button } from "react-bootstrap";
import CartItemsFragment from "./CartItems/CartItemsFragment"
import BackendClient from "../../BackendClient"

export default class CartLanding extends React.Component {
    bringUpCheckout = () => {
        BackendClient.launchBilling(this.props.cart["id"],(response)=>{
            var urlResponse = response.data.url;
            window.location.href = urlResponse;
        }, null)
    }
    render() {
        var isCartEmpty = this.props.cart === undefined || this.props.cart === null || this.props.cartItems === null || this.props.cartItems.length === 0
        return (
            <div className="cart col-12 col-sm-8">
                <div className="row">
                    <h2 className="cart-header">Cart</h2>
                </div>
                {isCartEmpty && 
                    <div className="row">
                        <p>Your cart is empty.</p>
                    </div>
                } 
                {!isCartEmpty && 
                    <div className="row">
                        <CartItemsFragment cartItems={this.props.cartItems} modifyItemInCartCallback={this.props.modifyItemInCartCallback} isModifyingCartQuantity={this.props.isModifyingCartQuantity}/>
                    </div>
                }
                {!isCartEmpty && 
                    <div className="row">
                        <div className="d-grid gap-2">
                            <Button variant="outline-primary" className="checkout-button" onClick={this.bringUpCheckout}>Checkout on the GameCrafter</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}