
import React from "react";
import "./Concepts.css"

type ConceptProps = {
    concept:string|React.ReactNode
}

export default class Concept extends React.Component<ConceptProps> {
    render() {
        var element = this.props.concept as React.ReactNode
        if (this.props.concept as string !== undefined) {
            element = <p className="blurb">{this.props.concept as string}</p>
        }
        return <div className="row" key={Math.random()}>
            <div className="col-12">
                {element}
            </div>
        </div>
    }
}

