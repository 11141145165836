import React from "react";
import "../TopBar.css"

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import NavbarLink from "./NavbarLink";
import Nav from 'react-bootstrap/Nav';
import { ColorScheme } from "../ColorScheme";

export enum EndpointType {
    Local = 1,
    Global = 2
}

export type Endpoint = {
    type: EndpointType,
    endpoint:string,
    name: string
}

type TopBarProps = {
    cartId: number,
    mainPageName: string,
    topBarLinks: Array<Endpoint>,
    colorScheme:ColorScheme
}

export class TopBar extends React.Component<TopBarProps> {
    render() {
        return (
            <Navbar className={"topbar " + this.props.colorScheme} bg="light" fixed="top">
                <Container>
                    <Navbar.Brand className={this.props.colorScheme}>
                        <Link to="/">{this.props.mainPageName}</Link>
                    </Navbar.Brand>
                    <Nav className="justify-content-end">
                        {this.props.topBarLinks.map((endpoint: Endpoint) => <NavbarLink colorScheme={this.props.colorScheme} key={endpoint.name} endpoint={endpoint}/> )}             
                        {/* { this.props.cartId !== null && <CartNav colorScheme={this.props.colorScheme} cartId={this.props.cartId}/> } */}
                    </Nav>                                       
                </Container>
            </Navbar>
        );
    }
}