import React from "react";
import "./ShopOptions.css"
import { Image, Button, Card } from "react-bootstrap";

export default class Product extends React.Component {
    visitRobloxGame = () => {
        window.location.href = this.props.product.link
    }

    render() {
        return (
            <Card className="productCard">
                <Card.Img className="productImage" as={Image} variant="top" src={this.props.product["image_link"]} alt={this.props.product.name + " Image"}/>
                <Card.Body>
                    <Card.Title className="productTitle">{this.props.product.name}</Card.Title>
                    <Card.Text className="productDescription">{this.props.product.description}</Card.Text>
                    <Button variant="outline-dark" onClick={this.visitRobloxGame} className="productActionButton">More Info</Button>
                </Card.Body>
            </Card>
        );
    }
}