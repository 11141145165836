import React from "react";
import FeaturedProducts from "./FeatureProduct/FeaturedProducts";
import RecentNews from "./News/RecentNews";
import NewsletterFooter from "./NewsletterFooter";
import TopGames from "./TopGames/TopGames";
import Explanation from "./Explanation";

export default class NewsletterSignupLanding extends React.Component {
    render() {
        return (
            <div className="row justify-content-center ">
                <Explanation/>
                {/* <FeaturedProducts/> */}
                {/* <TopGames/> */}
                {/* <RecentNews/> */}
                {/* <NewsletterFooter/> */}
            </div>
        );
    }
}