export default [
    {
        "name": "Caps and Hammers",
        "subtitle": "Lunchbreak Length Cold War Card Game",
        "pitch": "Take the helm of a Superpower and lead them to global domination. Compete for influence, combo your Assets together, push your luck with hidden information, read your opponent, and plan how to use your limited resources.",
        "sellSheetUrl": "https://www.gonextgames.com/capsAndHammersSellSheet.png",
        "shareUrl": "https://www.gonextgames.com/games/caps-and-hammers",
        "tabletopPlaygroundLink": "https://mod.io/g/tabletopplayground/m/caps-and-hammers1",
        "tabletopSimulatorLink": "https://steamcommunity.com/sharedfiles/filedetails/?id=3314994076",
        "printAndPlayLink": "https://www.gonextgames.com/capsAndHammersPrintAndPlay.pdf",
        "rulesPdfLink": "https://www.gonextgames.com/capsAndHammersRules.pdf",
        "youtubePitchId": null,
    },
    // d
]