
import React from "react";
import "./CompanyInfo.css"
import BigConcept from "../Learn/Concepts/BigConcept";
import bigConcepts  from "../Learn/Concepts";
import NewsletterConversion from "../Learn/Concepts/NewsletterConversion"
export default class BlogLanding extends React.Component {
    render() {
        return (
            <div className="row">

                <div className="content col-12 col-sm-8">
                    <h1 className="content-header">Blog & News</h1>
                    {bigConcepts.map((bigConcept) => <BigConcept bigConcept={bigConcept}/>)}
                    <NewsletterConversion/>
                </div>
            </div>
        );
    }
}