import React from "react";
import "../CompanyInfo.css"
import LinkList from "./LinkList";
import { ColorScheme } from "../ColorScheme";
type CompanyInfoProps = {
    companyName: string,
    supportEmail: string,
    discordInviteUrl: string,
    colorScheme: ColorScheme
}

export default class CompanyInfo extends React.Component<CompanyInfoProps> {
    render() {
        return (
            <div className={"company-info col-12 " + this.props.colorScheme} >
                <div className="row justify-content-between">
                    <div className="col-12 col-sm-3 company-info-name">
                        <h4>{this.props.companyName}</h4>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="row">
                            <LinkList listName="Company" links={[
                                {
                                    url: "/privacy",
                                    name: "Privacy Policy",
                                    type: "localLink",
                                },
                                {
                                    url: "/terms",
                                    name: "Terms and Conditions",
                                    type: "localLink",
                                }
                            ]}/>
                            <LinkList listName="Support" links={[
                                {
                                    url: `mailto:${this.props.supportEmail}`,
                                    name: "Contact Us",
                                    type: "href",
                                },
                            ]}/>
                            {/* <LinkList listName="Follow Us" links={[
                                {
                                    url: this.props.discordInviteUrl,
                                    name: "Discord",
                                    type: "href",
                                },
                            ]}/> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}