
import React from "react";
import "./CompanyInfo.css"
export default class SubmitDesignsLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                <h1 className="content-header">Submit a Game Design</h1>
                    <p>Do you think your design fits well with our catalogue? Submit a design! All submissions must include a rulebook in pdf format.</p>
                    <p>The following things would be helpful to me to understand your game:</p>
                    <ul>
                        <li>A sell sheet</li>
                        <li>A quick video rundown of the mechanics or example gameplay</li>
                        <li>Your vision of the game</li>
                    </ul>
                </div>
            </div>
        );
    }
}