
import React from "react";
import "./CompanyInfo.css"

export default class RetailerLanding extends React.Component {
    render() {
        return (
            <div className="row">

                <div className="content col-12 col-sm-8">
                    <h1 className="content-header">Retailer Resources</h1>
                    <p>Thank you for your interest in Next Day Game's products. If you would like to stock our games, please contact oliver@gonextgames.com.</p>
                    
                    <h2>Useful Stuff</h2>
                    <ul>
                        <li><a href="www.nextday.games">Youtube Playlist of 30 second Trailers</a></li>
                        <li><a href="www.nextday.games">Join the Discord to get quick questions answered.</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}