import React from "react";
import "./Content.css"
import PhysicalProduct from "./ShopOptions/PhysicalProduct";
import RobloxProduct from "./ShopOptions/RobloxProduct";

export default class ShopLanding extends React.Component {    
    createElementsForProducts(products) {
        var productElements = []
        var isLoading = this.props.isModifyingCartQuantity > 0
        for(var i = 0; i < products.length; i++) {
            var product = products[i]
            var productElement = undefined
            if (product.product_type === "BoardGame") {
                productElement = 
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 product" key={product.product_guid}>
                        <PhysicalProduct isLoading={isLoading} cart={this.props.cart} cartItems={this.props.cartItems} onClickCallback={this.props.addProductToCartCallback} product={product}/>
                    </div>
            }
            if (product.product_type === "RobloxGame") {
                productElement = 
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 product" key={product.product_guid}>
                        <RobloxProduct product={product}/>
                    </div>
            } 
            if (productElement === undefined) {
                continue
            }
            productElements.push(productElement)
        }
        return productElements
    }

    render() {
        var productElements = this.createElementsForProducts(this.props.products)
        return (
            <div className="row shopOptions justify-content-center">
                {productElements}
            </div>
        );
    }
}