
import React from "react";
import Concept from "./Concept"
import "./Concepts.css"

type BigConceptProps = {
    bigConcept:any
}

export default class BigConcept extends React.Component<BigConceptProps> {
    render() {
        return <div className="row justify-content-md-center">
            <div className="col-12 col-sm-6">
                <h2 className="big-concept-header">{this.props.bigConcept["name"]}</h2>
                <p>by {this.props.bigConcept["author"]} {this.props.bigConcept["authoredDate"]}</p>
                {this.props.bigConcept["concepts"].map((concept:any) => <Concept key={Math.random()} concept={concept}/>)}
            </div>
        </div>
    }
}