import './App.css';

import React from "react";

import ShopLanding from "./components/Shop/ShopLanding.js";
import BackendClient from "./BackendClient";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import CartLanding from "./components/Cart/CartLanding.js";
import TermsOfUseLanding from "./components/Company/TermsOfUseLanding"
import PrivacyLanding from "./components/Company/PrivacyLanding"
import LearnLanding from './components/Learn/LearnLanding';
import NewsletterSignupLanding from './components/Main/NewsletterSignupLanding';
import Landing from './components/Main/Landing/Landing';
import PressLanding from './components/Company/PressLanding';
import RetailerLanding from './components/Company/RetailerLanding';
import ConventionLanding from './components/Company/ConventionLanding';
import BlogLanding from './components/Company/BlogLanding';
import AboutUsLanding from './components/Company/AboutUsLanding';
import SubmitDesignsLanding from './components/Company/SubmitDesignsLanding';
import ArtLanding from './components/Company/ArtLanding';
import { EndpointType } from './components/Main/Landing/TopBar/TopBar';
import GamesLanding from './components/Games/GamesLanding.js';

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cartId: localStorage.getItem("cartId"),
            cart: null,
            cartItems: [],
            products: [],
            isModifyingCartQuantity: 0,
        };
    }

    requestCartItems = (cartId) => {
        var getCartWithItems = (responseWithCartItems) => {
            this.setState({cartItems: responseWithCartItems["data"]["cartItems"]["items"]})
        }
        BackendClient.getCartItems(cartId, getCartWithItems)
        
    }

    receiveResponseWithNewCart = (responseWithCart) => {
        this.requestCartItems(responseWithCart["data"]["cart"]["id"])
        
        localStorage.setItem("cartId", responseWithCart["data"]["cart"]["id"])
        this.setState({
            cartId: responseWithCart["data"]["cart"]["id"],
            cart: responseWithCart["data"]["cart"],
        })
    }

    createCart = (addItemToCartCallback) => {
        var afterCreatingCartCallback = (responseWithCart) => {
            addItemToCartCallback(responseWithCart["data"]["cart"]["id"])
            this.receiveResponseWithNewCart(responseWithCart)
        }
        BackendClient.createCart(afterCreatingCartCallback, null)
    }

    addProductToCartCallback = (product) => {
        var cartId = this.state.cartId

        var addItemToCartCallback = (cartId) => {
            var addItemToCartAndFinishAdding = (response) => {
                this.receiveResponseWithNewCart(response)
                this.setState({isModifyingCartQuantity: this.state.isModifyingCartQuantity - 1})
            }
            BackendClient.addSkuToCart(cartId, product.link, addItemToCartAndFinishAdding, null)
            this.setState({
                isModifyingCartQuantity: this.state.isModifyingCartQuantity + 1,
            })
        }

        if (cartId === null) {
            this.createCart(addItemToCartCallback)
            return
        }
        addItemToCartCallback(cartId)
    }

    getExistingCart = () => {
        if (this.state.cartId === null) {
            return
        }
        BackendClient.getCart(this.state.cartId, this.receiveResponseWithNewCart, null)
    }

    modifyItemInCartCallback = (skuId, value) => {
        var addItemToCartAndFinishAdding = (response) => {
            this.receiveResponseWithNewCart(response)
            this.setState({isModifyingCartQuantity: this.state.isModifyingCartQuantity - 1})
        }
        BackendClient.modifyCartSkuQuantityByValue(this.state.cartId, skuId, value, addItemToCartAndFinishAdding, null)
        this.setState({isModifyingCartQuantity: this.state.isModifyingCartQuantity + 1})
    }

    requestProducts = () => {
        var thenCallback = response => {
            this.setState({products: response["data"]["products"]});
        };
        BackendClient.getProducts(thenCallback, null)
    }

    componentDidMount = () => {
        this.getExistingCart()
        this.requestProducts()
    }

    render = () => {
        var cartItemCount = 0
        if (this.state.cart !== null) {
            cartItemCount =  Number(this.state.cart["item_count"])
        }
        var links = [
            // { name: "Shop", endpoint: "shop", type: EndpointType.Local },
            // { name: "Blog", endpoint: "blog", type: EndpointType.Local },
            // { name: "Press", endpoint: "press", type: EndpointType.Local },
            // { name: "Retailers", endpoint: "retailers", type: EndpointType.Local },
            // { name: "Join the Demo Team", endpoint: "conventions", type: EndpointType.Local },
            // { name: "Pitch a Game", endpoint:"submitDesigns", type: EndpointType.Local },
            // { name: "Submit an Art Portfolio", endpoint:"art", type: EndpointType.Local },
            // { name: "About Us", endpoint: "about", type: EndpointType.Local },
        ]
        return (
            <BrowserRouter>
                <div className="App container-fluid">
                    <Landing cartId={this.state.cartId} mainPageName="Go Next Games" companyName="Go Next Games" supportEmail='oliver@gonextgames.com' discordInviteLink='https://discord.gg/RqUVQymRup' cartItemCount={this.state.cartItems.length} topBarLinks={links}>
                        <Routes>
                            <Route key="home" path="/" element={
                                <NewsletterSignupLanding/>
                            }/>
                            <Route key="games"  path="/games/*" element={
                                <GamesLanding/>
                            }/>
                            <Route key="privacyLanding" path="/privacy" element={
                                <PrivacyLanding/>
                            }/>
                            <Route key="termsOfUseLanding" path="/terms" element={
                                <TermsOfUseLanding/>
                            }/>
                        </Routes>
                    </Landing>
                </div>
            </BrowserRouter>
        )
    }
}
