const bigConcepts = [
    // {
    //     name: "Local Conventions",
    //     concepts: [
    //         "Houston",
    //         <span><a href="http://owlcon.com/">Owl Con</a></span>,
    //         <span><a href="https://deltahcon.com/about_us/">Delta HCon</a></span>,
    //         <span><a href="https://www.comicpalooza.com/general-information/">Comicpalooza</a></span>,
    //         "Austin",
    //         <span><a href="https://tabletop.events/conventions/chupacabracon-x">Chupacabracon</a></span>,
    //         "Dallas",
    //         <span><a href="https://animefest.org/">Anime Fest</a></span>,
    //         <span><a href="https://www.all-con.org/">All Con</a></span>,
    //         <span><a href="https://stoutpanda.github.io/dgm_landing_page/">Dallas Games Marathon</a></span>,
    //         <span><a href="https://scgcon.starcitygames.com/event/scg-con-dallas-2023/?utm_source=FanCons.ca&utm_medium=web&utm_campaign=FanCons.com">SCG Con</a></span>,
    //         <span><a href="https://fanexpohq.com/fanexpodallas/about-the-show/">Fan Expo</a></span>,
    //         "Irving",
    //         <span><a href="https://a-kon.com/">A-Kon</a></span>,
    //         "College Station",
    //         <span><a href="https://etuttle0.wixsite.com/aggiecon">AggieCon</a></span>,
    //         "Wichita Falls",
    //         <span><a href="https://www.fallscon.net/">Fallscon</a></span>,
    //     ],
    // },
    // {
    //     name: "Austin Player Meetups",
    //     concepts: [
    //         "South Austin Game Night, Tuesday 6:00 - 11:00pm @ Rockin' Tomato",
    //         "Gaming, Wednesday 2:00 - 6:30pm @ Wonko's Toys and Games",
    //         "Board Game Night, Thursday 6:30 - 10:30pm @ Whose Turn Is It Games"
    //     ],
    // },
    // {
    //     name: "Austin Designer Meetups",
    //     concepts: [
    //         <span><a href="https://www.meetup.com/austin-board-game-designers-and-play-testers/">Research Night, Wednesday 7:00pm @ Emerald Tavern Games and Cafe</a></span>,
    //         <span><a href="https://www.meetup.com/austin-board-game-designers-and-play-testers/">Playtest, Saturday 7:00pm @ Emerald Tavern Games and Cafe</a></span>
    //     ]
    // },
    // {
    //     name: "Social Media Guide",
    //     concepts: [
    //         "Pairing in-person sharing with judicious social media use is a great way to expand your reach. We will need discovery tools, engagement tools, and capture tools.",
    //         "Youtube shorts, Tiktoks, and Instagram reels are the best platforms for discovery.",
    //         "Twitter is the middle ground between discovery and .",
    //         "Facebook is a mainstay of board game development.",
    //         "Blogging platforms like Medium or Wordpress are effective for marketing yourself to other developers. That doesn't make it useful for marketing to our players. When was the last time you read the personal blog of a vidoe or board game designer you liked?",
    //     ]
    // },
    // {
    //     name: "Newsletters are Good, Actually",
    //     concepts: [
    //         "A newsletter is the bread and butter of marketing.",

    //         "Marketing is an event. We create an event because making decisions, even ones that better our lives, is hard. A time bound event says 'hey, you don't have long to get a slice of this, get it while you can'.",
            
    //         "A core assumption here is that you believe your game makes people's lives better. Go Next Games does not publish games that encourage addiction, gambling, or toxicity.",
    //         "If you believe in your game's ability to enable expression, to foster connection, and to activate mental almonds, then sharing it is an act of generosity.",

    //         "With newsletters, you KNOW that emails you send will be seen by your players. This guarantee allows us to marshal our following for one big push that is demonstratively superior to event-less ad-marketing.",
    //         "Even products that are always online construe 'summer-sales' and 'christmas deals' to create artificial events.",
    //         "For games, we can use a crowdfunding campaign to naturally create an event.",
    //     ]
    // },
    // {
    //     name: "Prototype Basics",
    //     concepts: [
            
    //     ]
    // },
    {
        name: "How I Write Rules for Ultra Clarity",
        author: "Oliver Barnum",
        authoredDate: "2023-10-12",
        concepts: [
            "There are many wrong ways to explain your rules:",
            "'The players will draw cards' -> The players wont.",
            "'The decks are shuffled when the round ends' -> The players forgot.",

            "Instead, write your rules in the Mandative Subjunctive. People use the Mandative Subjunctive when commanding others. An example of a Mandative Subjunctive sentence is 'Ok place your token on your space, now draw two cards'. It is like Active Voice, but it includes a command.",
            "Let's look at the Mandative Subjunctive for the examples above:",
            "'Draw cards.'",
            "'Shuffle the decks once the round ends.'",

            "Express your entire rulebook in the Mandative Subjunctive. It is very easy to slip out of it, particularly when who you are commanding is ambiguous. To prevent ambiguity, explain which player or players own the object acted on by the command. Assume that someone is reading this aloud:",
            "'At the start of the round, draw two cards from YOUR deck.' -> All players draw two cards.",
            "'Deal two cards BETWEEN the players from the SHARED deck.' -> One player deals two cards between the two players.",
            "'IF YOU ARE THE RED PLAYER, draw two cards from your deck.' -> One player draws cards.",
            "'Draw four cards FOR THE TABLE.' -> One player draws four cards on behalf of everyone.",

            "Do not include strategy suggestions in your rules. It is already easy to create a voluminous rulebook. Strategy is discovered through the course of play. Strategy sections come off as patronizing. Strategy sections prevent players from deriving meaning from mastery and discovery. Strategy sections mean that you have an accessibility problem. Address this usability issue at the design level.",
            
            "Include a component list, setup instructions, who the players are, how to play rounds, and win conditions.",
            "Most board games have concrete win conditions. Many games, like Dungeons and Dragons, have open-ended win conditions like 'have fun'. Explain whether your game is concrete or open-ended.",
            
            "The first piece of information a player needs is how to win. No matter what you do, the way to win the game will lack context. ",
            "'Win the game by having the most victory points at the end of seven rounds' -> What are victory points? How do I get them? What is a round?",
            "A rulebook explores a tree of concepts that each depend on their parent concepts. In the above example, winning has two parent concepts: rounds and victory points. Victory points and rounds will have other concepts that they depend on. Thus, you cannot say you have explained how to win until you have explained all parents of parents.",
            "Trees are nonlinear, yet you read rulebooks linearly. It is up to the designer to cleverly arrange the concepts to bridge this gap.",

            "Here is an example of a Mandative Subjunctive, linear explanation of a tree of concepts:",
            "'Win the game by collecting more Victory Points than your opponent. Collect Victory Points by winning Countries each round. Win a Country by exerting more total Influence on it than your opponent. Exert Influence by deploying your Assets into Countries from your hand. At the end of the round, sum the Influence of each of your Assets on a Country to determine your total Influence there. Fight for three Countries each round. Play rounds until your run out of Countries.'"
            
        ]
    },
    // {
    //     name: "The Costs of Ads",
    //     concepts: [
    //     ]
    // },
    // {
    //     name: "Design Constraints",
    //     concepts: [
    //     ]
    // },
    // {
    //     name: "The Translation Pipeline",
    //     concepts: [
    //     ]
    // },
    // {
    //     name: "Art Programs",
    //     concepts: [
    //         "Inkscape, Illustrator, Indesign, Photoshop"
    //     ]
    // }
    //https://cardboardedison.com/playtest-groups
]
export default bigConcepts 