
import React from "react";
import "./CompanyInfo.css"

export default class PressLanding extends React.Component {
    render() {
        return (
            <div className="row">

                <div className="content col-12 col-sm-8">
                    <h1 className="content-header">Press Resources</h1>
                    
                    <h2>Contacting Me</h2>
                    <p>Please contact oliver@gonextgames.com. Please include the following information:</p>
                    <ul>
                        <li>Your Location</li>
                        <li>Links to your Channel</li>
                        <li>How you want to work together</li>
                        <li>Review copies you might like</li>
                    </ul>
                    <h2>Signup for the Press Newsletter</h2>
                    <p>If you want to know when review copies are available, or to receive press releases in advance of any announcements. Sign up for our press newsletter.</p>
                    <p>Consider joining the Discord to get any quick questions answered.</p>
                    <h2>Marketing Assets</h2>
                    <p><a href="www.nextday.games">Go Next Games logos</a></p>
                    <h3>Game Specific Marketing Material</h3>
                    <ul>
                        <li><a href="www.nextday.games">Peace of Westphalia</a></li>
                        <li><a href="www.nextday.games">Caps and Hammers</a></li>
                        <li><a href="www.nextday.games">BigTop</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}