import React from "react";
import "./CartItems.css"

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export default class CartItem extends React.Component {
    render() {
        return (
            <div className="cart-item col-12">
                <div className="row">
                    <div className="col-8">
                        <p className="name">{this.props.item["name"]}</p>
                    </div>
                    <div className="col-4">
                        <p className="price">{this.props.item["quantity"]}x ${this.props.item["retail_price_each"]}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 col-sm-10"></div>
                    <div className="col-4 col-sm-2">
                        <ButtonGroup className="quantity-button-group">
                            <Button 
                                variant="outline-primary" 
                                disabled={this.props.isModifyingCartQuantity} 
                                onClick={()=>{this.props.modifyItemInCartCallback(this.props.item["sku_id"], -1)}} 
                                size="sm">-1
                            </Button>
                            <Button 
                                variant="outline-primary" 
                                disabled={this.props.isModifyingCartQuantity} 
                                onClick={()=>{this.props.modifyItemInCartCallback(this.props.item["sku_id"],  1)}} 
                                size="sm">+1
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        );
    }
}