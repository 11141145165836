
import React from "react";
import "./Landing.css";

import {Endpoint, TopBar} from "./TopBar/TopBar"
import CompanyInfo from "./CompanyInfo/CompanyInfo"
import Footer from "./Footer/Footer"
import { ColorScheme } from "./ColorScheme";

type LandingProps = {
    cartItemCount: number,
    cartId: number,
    children?: React.ReactNode,
    mainPageName: string,
    companyName: string,
    supportEmail: string,
    discordInviteLink: string,
    topBarLinks: Array<Endpoint>
    colorScheme:ColorScheme
}

export default class Landing extends React.Component<LandingProps> {
    render() {
        return (
            <div className="landing row">
                <div className="col read">
                    {/* <TopBar colorScheme={this.props.colorScheme} mainPageName={this.props.mainPageName} cartId={this.props.cartId} topBarLinks={this.props.topBarLinks}/> */}
                    <div className="firstDiv"></div>

                    <div className="row main-content-row g-0">
                        <div className="col main-content-col">
                            {this.props.children}
                        </div>
                    </div>

                    <div className="firstDiv"></div>
                    <CompanyInfo colorScheme={this.props.colorScheme} companyName={this.props.companyName} discordInviteUrl={this.props.discordInviteLink} supportEmail={this.props.supportEmail}/>
                    <Footer colorScheme={this.props.colorScheme} companyName={this.props.companyName}/>
                </div>
            </div>
        );
    }
}