
import React from "react";
import "./CompanyInfo.css"

export default class ConventionLanding extends React.Component {
    render() {
        return (
            <div className="row">

                <div className="content col-12 col-sm-8">
                    <h1 className="content-header">Conventions</h1>
                    <p>Here's our convention schedule for the coming year:</p>
                    <ul>
                        <li>Protospiel ATX Austin, TX Oct 21</li>
                        <li>BGGcon Dallas, TX Nov 15</li>
                    </ul>
                </div>
            </div>
        );
    }
}