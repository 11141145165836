import React from "react";
import BigConcept from "./Concepts/BigConcept";
import bigConcepts  from "./Concepts";
import NewsletterConversion from "./Concepts/NewsletterConversion";

export default class LearnLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col">
                    {bigConcepts.map((bigConcept) => <BigConcept bigConcept={bigConcept}/>)}
                    <NewsletterConversion/>
                </div>
            </div>
        );
    }
}