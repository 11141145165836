
import React from "react";
import "./Concepts.css"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
type NewsletterConversionState = {
    gamePrice:number,
    fixedExpenses:number
}

export default class NewsletterConversion extends React.Component<{}, NewsletterConversionState> {
    state: NewsletterConversionState
    
    constructor(props:any) {
        super(props)
        this.state = {
            gamePrice: 30,
            fixedExpenses: 6000
        }
    }

    updatePrice(price:number) {
        if (price <= 0) {
            return
        }
        this.setState({
            gamePrice: price
        })
    }
    updateFixedExpenses(fixedExpenses:number) {
        this.setState({
            fixedExpenses: fixedExpenses
        })
    }

    render() {
        var groupSize = this.state.fixedExpenses/this.state.gamePrice*0.3
        return <div className="row justify-content-md-center">
            <div className="col-6 col-sm-6">
                <h2>When do I launch?</h2>
                <p>by Oliver Barnum 2023-10-12</p>
                <p>Let's calculate how many people you need in your newsletter before you launch your crowdfunding campaign.</p>
                <Form>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Profit per Game in USD</InputGroup.Text>
                        <Form.Control placeholder="30" value={this.state.gamePrice} onChange={(value) => {
                            if (Number(value.currentTarget.value) == null) {
                                return
                            }
                            this.updatePrice(Number(value.currentTarget.value))
                        }}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Fixed expenses for project in USD</InputGroup.Text>
                        <Form.Control placeholder="6000" value={this.state.fixedExpenses} onChange={(value) => {
                            if (Number(value.currentTarget.value) == null) {
                                return
                            }
                            this.updateFixedExpenses(Number(value.currentTarget.value))
                        }}/>
                    </InputGroup>
                </Form>
                <p>Minimum wage for a year is $15,000 in Texas. Will you have worked a year on this?</p>

                <p>You need <strong>{this.state.fixedExpenses/this.state.gamePrice} people to back the project</strong> at a price that purchases the game.</p>
                <p>The <strong>crowdfunding platform will provide you {Math.floor(this.state.fixedExpenses/this.state.gamePrice*0.15)}</strong> people through discovery.</p>
                <p>That means you need to bring {(this.state.fixedExpenses/this.state.gamePrice) - Math.floor(this.state.fixedExpenses/this.state.gamePrice*0.15)} people yourself.</p>
                <p>It's normal for only 10% of your newsletter signups to convert into purchases.</p>
                <p>That means <strong>you need to have {((this.state.fixedExpenses/this.state.gamePrice) - Math.floor(this.state.fixedExpenses/this.state.gamePrice*0.15))*10} people in your newsletter.</strong></p>
                <p>{groupSize} people need to show up on launch day, another {groupSize} will show up on the last day, and the remaining {groupSize} will show up throughout the launch.</p>
            </div>
        </div>
    }
}

