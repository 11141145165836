
import React from "react";

type PersonBadgeProps = {
    imageUrl:string,
    name:string,
    role:string,
    description:string
}

export default class PersonBadge extends React.Component<PersonBadgeProps> {
    render() {
        return <div className="col-12 col-sm-6">
            <p>{this.props.imageUrl}</p>
            <h3>{this.props.name}</h3>
            <h3>{this.props.role}</h3>
            <p>{this.props.description}</p>
        </div>
    }
}