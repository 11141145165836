
import React from "react";
import "./CompanyInfo.css"
import PersonBadge from "./PersonBadge";
export default class AboutUsLanding extends React.Component {
    render() {
        return (
            <div className="row">

                <div className="content col-12 col-sm-8">
                    <h1 className="content-header">About Go Next Games</h1>
                    <p>Go Next Games is an Austin, Texas based board game publisher.</p>
                    <p>oliver@gonextgames.com</p>
                    <p>801 W 5th Street Austin, Texas, 78703</p>
                    <h2>The Company</h2>
                    <div className="row">
                        <PersonBadge name="Oliver Barnum" role="Founder" description="Oliver is an Ex Roblox Engineer/Designer."/>
                    </div>
                </div>
            </div>
        );
    }
}